import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "done" ]
  static values = {parentId: Number}

  initialize() {
  }

  // checkVisible(elm) {
  //   var rect = elm.getBoundingClientRect();
  //   var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  //   return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  // }
  //
  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    // console.log("Connecting item controller...")
    componentHandler.upgradeElements(this.element.querySelectorAll("[class*=mdl-"))
    if (this.data.get("lazy") == "true") {
      // if (this.checkVisible(this.element))
      //   this.refresh();
    }

    // if (this.alreadyInDom()) {
    //   console.log("removing myself")
    //   this.element.remove()
    // }
  }

  disconnect() {
    // console.log("Disconnecting item controller...")
  }

  alreadyInDom() {
    if (this.element.id) {
      return document.querySelectorAll(`#${this.element.id}`).length > 1
    }
    return false
  }

  refresh() {
    var itemId = this.data.get("id");
    var partial = this.data.get("partial");
    var lite = this.data.get("lite");
    var that = this;
    $.ajax({
      url: '/jobs/' + itemId + '?partial=' + partial + '&lite=' + lite,
      success: function(result) {
        console.log($(this.element));
        $(that.element).replaceWith(result);
        hookupJavascript();
    }});
  }

  checkdone() {
    var isChecked = this.doneTarget.checked;
    var itemId = this.data.get("id");
    var that = this;
    $.ajax({
      url: '/jobs/' + itemId + '/done?&done=' + isChecked,
      method: 'post'
    });
  }

  openChat() {
    console.log("In itemList#openChat")

    $('#item-preview').addClass('active');

    var itemId = this.data.get("id");
    console.log("Clicked job ID: " + itemId);

    var spinner = '<div class="text-center"><div class="mdl-spinner mdl-js-spinner is-active mt-5 mb-5"></div></div>'
    $('#item-preview').html(spinner);
    componentHandler.upgradeAllRegistered();

    $.ajax({
      url: '/assistant/chats/' + itemId,
      success: function(result) {
        // console.log(result);
        $('#item-preview').html(result);
        setTimeout(function() {
          $('#item-preview').find("textarea")[0].focus();
        }, 200);
        hookupJavascript();
    }});
  }

  openDataroomItem() {
    console.log("In itemList#openDataroomItem")

    $('#item-preview').addClass('active');

    var itemId = this.data.get("id");
    var viewType = this.data.get("view");
    console.log("Clicked job ID: " + itemId);

    var spinner = '<div class="text-center"><div class="mdl-spinner mdl-js-spinner is-active mt-5 mb-5"></div></div>'
    $('#item-preview').html(spinner);
    componentHandler.upgradeAllRegistered();

    var url = '/dataroom_item/' + itemId
    if (viewType == "edit") {
      url = url + '/edit'
    }

    $.ajax({
      url: url,
      success: function(result) {
        // console.log(result);
        $('#item-preview').html(result);
        hookupJavascript();
    }});
  }

  openItem() {
    console.log("In itemList#openItem")

    $('#item-preview').addClass('active');

    var itemId = this.data.get("id");
    var contextId = this.data.get("context-id");
    console.log("Clicked job ID: " + itemId);
    console.log("Clicked context ID: " + contextId);

    var spinner = '<div class="text-center"><div class="mdl-spinner mdl-js-spinner is-active mt-5 mb-5"></div></div>'
    $('#item-preview').html(spinner);
    componentHandler.upgradeAllRegistered();

    let url
    if (contextId) {
      url = '/jobs/' + contextId + '/one_job'
    } else {
      url = '/jobs/' + itemId + '/one_job'
    }

    $.ajax({
      url: url,
      success: function(result) {
        // console.log(result);
        $('#item-preview').html(result);
        $(".mdl-layout__drawer-right .jobs-table").sortable({
          update: function(e, ui) {
            $.ajax({
              url: $(this).data("url"),
              type: "PATCH",
              data: $(this).sortable('serialize'),
            })},
          handle: '.handle',
          placeholder: "sortable-placeholder"
        });
        hookupJavascript();

        // Show/Hide the breadcrumbs based on whether the parent is the root item on the page
        // var rootItem = $('#mainItemHeader > .js-job-details-row')[0].item
        // var rootId = rootItem.data.get('id')
        // var hide = (rootId == parentId)
        // console.log("Should hide for parentId: (" + parentId + ") " + hide);
        //
        // if (!hide)
        //   $('#item-preview .breadcrumbs').show();
    }});
  }

  showCopy() {
    console.log("in showCopy")

    // add the new html to the collection; is there a better way with Stimulus?
    var eventDetails = event.detail[2]
    var row = eventDetails.responseText
    var currentItem = $("#job_" + this.data.get("id"))
    $(currentItem)[0].insertAdjacentHTML("afterend", row)
    hookupJavascript();

    // Increment collection count
    var element = $( ".collection.active" )
    if (element.data("job-id") == this.data.get("parent-id")) {
      count = Number( element.find( ".badge" )[0].innerHTML );
      $( ".collection.active" ).find( ".badge" )[0].innerHTML = count + 1;
    }
  }
}
