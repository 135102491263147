import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list", "message"];

  initialize() {
    // console.log("Initializing card with onboarding controller.");
  }

  connect() {
    // console.log("card_with_onboarding controller connected.");

    // Check if the targets are initialized correctly
    if (this.hasListTarget) {
      console.log("List target found:", this.listTarget);
    } else {
      console.error("List target not found.");
    }

    if (this.hasMessageTarget) {
      console.log("Message target found:", this.messageTarget);
    } else {
      console.error("Message target not found.");
    }

    // Initial message update
    this.updateMessage();
    this.observeChanges();
  }

  updateMessage() {
    console.log("updateMessage called.");
  
    // Filter out empty text nodes or irrelevant elements
    const itemElements = Array.from(this.listTarget.children).filter(
      (child) => child.nodeType === 1 // nodeType === 1 ensures we're only counting element nodes
    );
  
    const hasItems = itemElements.length > 0;
    console.log("List has items:", hasItems, "Number of valid items:", itemElements.length);
  
    // Toggle the visibility of the instructional message
    if (this.messageTarget) {
      this.messageTarget.style.display = hasItems ? "none" : "block";
      console.log("Message visibility updated. Visible:", !hasItems);
    } else {
      console.error("Message target not available to update.");
    }
  }  

  observeChanges() {
    console.log("observeChanges set up.");

    // Set up the MutationObserver to watch for changes in the list target
    const observer = new MutationObserver(() => {
      // console.log("DOM mutation detected. Checking for updates...");
      this.updateMessage();
    });

    if (this.listTarget) {
      observer.observe(this.listTarget, { childList: true });
      // console.log("Observer is watching for changes in the list.");
    } else {
      console.error("List target not found. Unable to observe changes.");
    }
  }
}
