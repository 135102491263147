// Define the function globally
window.moveTooltips = () => {
  console.log('Moving tooltips...');

  // Ensure the tooltip container exists
  let tooltipContainer = document.getElementById('tooltip-container');
  if (!tooltipContainer) {
    tooltipContainer = document.createElement('div');
    tooltipContainer.id = 'tooltip-container';
    document.body.appendChild(tooltipContainer);
    // console.log('Tooltip container created.');
  }

  // Select all tooltips not already in the container
  const tooltips = Array.from(document.querySelectorAll('[data-html]')).filter(
    tooltip => !tooltipContainer.contains(tooltip)
  );

  tooltips.forEach(tooltip => {
    // Skip moving tooltips that are inside the modal -- it isn't working, potentially due to z-indexes
    if (tooltip.closest('#modal')) {
      // console.log('Skipping tooltip inside #modal:', tooltip);
      return;
    }
    
    const mdlFor = tooltip.getAttribute('data-mdl-for');

    // Check if a tooltip with the same mdlFor already exists in the container
    const existingTooltip = tooltipContainer.querySelector(`[data-mdl-for="${mdlFor}"]`);

    if (existingTooltip) {
      // Remove the duplicate tooltip in the original location
      tooltip.remove();
      // console.log(`Duplicate tooltip removed for: ${mdlFor}`);
    } else {
      // Move the tooltip to the container
      tooltipContainer.appendChild(tooltip);
      // console.log(`Tooltip moved for: ${mdlFor}`);
    }
  });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', () => {
  // console.log('DOMContentLoaded event fired');
  moveTooltips();
});

document.addEventListener('ajax:complete', () => {
  // console.log('turbo:load event fired');
  moveTooltips();
});

document.addEventListener('turbo:load', () => {
  // console.log('turbo:load event fired');
  moveTooltips();
});
