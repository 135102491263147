import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing move controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
  }

  disconnect() {
    console.log("Disconnecting move controller...")
  }

}
