import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("Menu tooltip controller connected");
  }

  toggleMenu(event) {
    const menuButtonId = event.currentTarget.id; // ID of the menu button
    const tooltip = document.querySelector(`[data-mdl-for="${menuButtonId}"]`);
    const menu = document.querySelector(`.mdl-menu[for="${menuButtonId}"]`); // Find menu by `for` attribute
    const menuContainer = menu?.closest(".mdl-menu__container"); // Get the parent container of the menu
  
    console.log("Menu button ID:", menuButtonId);
    console.log("Tooltip:", tooltip);
    console.log("Menu:", menu);
    console.log("Menu container:", menuContainer);
  
    if (tooltip && menuContainer) {
      // Add a short timeout to wait for the container's state to fully update
      setTimeout(() => {
        const isMenuOpen = menuContainer.classList.contains("is-visible"); // Check visibility of the parent container
        console.log("Menu open:", isMenuOpen);
        tooltip.style.display = isMenuOpen ? "none" : "";
        console.log("Tooltip display:", tooltip.style.display);
      }, 50); // Adjust the timeout duration as needed
    }
  }
    
}
