import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "list" ]

  initialize() {
    console.log("Initializing application controller")
  }

}
