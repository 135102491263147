// app/javascript/controllers/visibility_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "checkbox"];

  connect() {
    // Retrieve the initial visibility from the data attribute
    const initialVisibility = this.element.dataset.initialVisibility === "true";
    console.debug("Initial visibility retrieved from data attribute:", this.element.dataset.initialVisibility);
    console.debug("Parsed initial visibility:", initialVisibility);
  
    // Set the initial state of the checkbox
    this.checkboxTarget.checked = initialVisibility;
    console.debug("Checkbox state set to:", this.checkboxTarget.checked);

    // Update the MDL display state
    const parentLabel = this.checkboxTarget.closest(".mdl-switch");
    if (parentLabel && parentLabel.MaterialSwitch) {
      if (initialVisibility) {
        parentLabel.MaterialSwitch.on();
        console.debug("MDL Switch toggled on.");
      } else {
        parentLabel.MaterialSwitch.off();
        console.debug("MDL Switch toggled off.");
      }
    } else {
      console.warn("MDL Switch not initialized or parentLabel not found.");
    }

    // Set the initial visibility of the container instantaneously
    if (initialVisibility) {
      this.containerTarget.style.display = ""; // Use default display
    } else {
      this.containerTarget.style.display = "none"; // Hide without animation
    }
  }  

  show() {
    $(this.containerTarget).slideDown(); // Show the container with animation
  }

  hide() {
    $(this.containerTarget).slideUp(); // Hide the container with animation
  }

  toggle(event) {
    const scope = "visibility";

    if (event.target.checked) {
      this.show();
    } else {
      this.hide();
    }

    // Send an AJAX PATCH request to update the settings
    $.ajax({
      url: "/users/settings", // Adjust to your routes if necessary
      type: "PATCH",
      data: {
        job_id: this.element.dataset.jobId,
        scope: scope,
        label: this.element.dataset.label,
        value: event.target.checked // Pass whether it's visible or not
      },
      headers: {
        Accept: "text/javascript" // Explicitly request JavaScript format
      },
      success: function (response) {
        console.log("Settings updated successfully:", response);
      },
      error: function (error) {
        console.error("Error updating settings:", error);
      }
    });
  }
}
